<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 6"
    title="Berekening huidige situatie"
    :description
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.calculationStepInvalid"
    :validation-state="wizardValidationStore.v$calculationRules"
    :disabled-next-button="!newSimulationStore.intermediateResults"
  >
    <template #content>
      <LoadingWrapper
        :loading="newSimulationStore.loadingIntermediate"
        :error="newSimulationStore.errorIntermediate"
      >
        <BaseCard
          v-for="(table, index) in tables"
          :key="index"
          inner
          class="overflow-auto"
        >
          <BaseTable v-bind="table">
            <template #title>
              {{ table.columns.title.label }}
              <WikiTooltipComponent
                v-if="table.columns.title.tooltip"
                :path="table.columns.title.tooltip"
              />
            </template>
            <template #title-total="{ row }">
              <span class="font-medium">{{ row.title }}</span>
            </template>
            <template #value-total="{ row }">
              <span class="font-medium">{{ row.value }}</span>
            </template>
          </BaseTable>
        </BaseCard>
      </LoadingWrapper>
    </template>

    <template #nextButton>
      Gegevens kloppen, ga verder
      <BaseIcon class="ml-2" name="arrow-right" :filled="false" />
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { formatNumber } from "@/services/formatterService"
import { useWizardStore } from "@/stores/wizardStore"
import useNewSimulationStore from "@/stores/newSimulationStore.js"
import {
  LoadingWrapper,
  BaseIcon,
  BaseTable,
  BaseCard,
} from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  /*
    Used to determine which step is currently active
    */
  isActive: {
    type: Boolean,
    required: true,
  },
  /*
    For determining wether to show the finished checkmark and summary of the inputfields.
    TODO: This step will probably be complete upon receiving a succesful response with a estimate
    TODO: Create a relevant function
    */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /*
    Used to determine if the step is enabled
    */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const wizardValidationStore = useWizardValidationStore()
const wizardStore = useWizardStore()
const newSimulationStore = useNewSimulationStore()
const wizardStepRef = ref()

const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

const description = computed(() => {
  return `Berekening van de huidige energetische situatie en een inschatting van de energierekening met een dynamisch contract gebaseerd op de marktprijzen van ${wizardStore.simulation.endDate?.year}.`
})

const sectionMap = {
  data_production: {
    title: "Opwek",
    format: "energy",
    tooltip: "/bst/wizard/feed-in",
  },
  data_demand: {
    title: "Verbruik",
    format: "energy",
    tooltip: "/bst/wizard/consumption",
  },
  data_costs: {
    title: "Kosten",
    format: "finance",
    tooltip: "/bst/wizard/costs",
  },
  data_yields: {
    title: "Baten",
    format: "finance",
    tooltip: "/bst/wizard/income",
  },
  data_result: {
    title: "Totaal",
    format: "finance",
    tooltip: "/bst/wizard/total",
  },
  data_availability: {
    title: "Beschikbaarheid van de aansluiting voor de batterij",
    format: "percentage",
    tooltip: "",
  },
}

const tables = computed(() => {
  const results = newSimulationStore.intermediateResults
  if (!results) return []
  // Map each dataframe to table component inputs
  const parsedResults = {
    ...results.current_energetic_table,
    ...results.current_financial_table,
    data_availability: {
      "Beschikbaarheid van de aansluiting voor levering":
        results.offtake_availability,
      "Beschikbaarheid van de aansluiting voor teruglevering":
        results.inflow_availability,
    },
  }
  const sections = []
  Object.entries(parsedResults).forEach(([name, values]) => {
    if (name in sectionMap) {
      const rows = Object.entries(values).map(([key, value], index) => {
        return {
          title: key,
          value:
            sectionMap[name].format === "energy"
              ? `${formatNumber(value / 1000, 1)} MWh`
              : sectionMap[name].format === "percentage"
                ? `${formatNumber(value, 1)}%`
                : `€ ${formatNumber(value, 2)}`,
          _id:
            name !== "data_availability" &&
            index === Object.keys(values).length - 1
              ? "total"
              : null,
        }
      })
      sections.push({
        columns: {
          title: {
            label: sectionMap[name].title,
            tooltip: sectionMap[name].tooltip,
          },
          value: { classes: "text-right" },
        },
        rows,
      })
    }
  })
  return sections
})

defineExpose({ getValidationState })

watch(
  [
    () => wizardStore.simulation,
    () => wizardStore.grid,
    () => wizardStore.excel,
    () => wizardStore.demand,
    () => wizardStore.solarParks,
  ],
  () => {
    newSimulationStore.intermediateResults = null
  },
  { deep: true },
)

watch(
  () => props.isActive,
  (active) => {
    if (active && !newSimulationStore.intermediateResults) {
      newSimulationStore.submitIntermediate(null, (error) => {
        console.error(
          `Error submitting intermediate data (code: ${error.code})`,
        )
      })
    }
  },
)
</script>
