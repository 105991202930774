<template>
  <BaseCard class="p-6">
    <TextTitle class="mb-4">Aannames</TextTitle>

    <div
      v-for="(item, index) in resultsStore.assumptions"
      :key="index"
      class="mb-4 rounded-2xl border border-gray-100 p-6"
    >
      <TextSubtitle>{{ item.title }}</TextSubtitle>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="formatContent(item.content)"></div>
    </div>
  </BaseCard>
</template>

<script setup>
import {
  BaseCard,
  TextTitle,
  TextSubtitle,
} from "@repowerednl/ui-component-library"
import { useResultsStore } from "@/stores/useResultsStore"
import DOMPurify from "dompurify"

const resultsStore = useResultsStore()
function formatContent(content) {
  return DOMPurify.sanitize(content.replace(/\r\n|\r|\n/g, "<br>"))
}
</script>
