<template>
  <BaseCard class="relative p-6">
    <template v-if="demoStore.active">
      <div class="absolute inset-0 z-50 flex items-center justify-center">
        <div
          class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 bg-white p-4 shadow-md"
        >
          <p class="text-xl font-medium">
            Alleen beschikbaar in betaalde versie
          </p>
          <a href="mailto:support@repowered.nl">
            <BaseButton color="secondary" class="w-fit">
              Contact opnemen
            </BaseButton>
          </a>
        </div>
      </div>
    </template>
    <TextTitle>Financiële situatie</TextTitle>

    <BaseCard
      v-for="(cardData, index) in tableData"
      :key="index"
      class="financial-table mt-4"
      :class="demoStore.active ? 'border-none blur-sm' : ''"
    >
      <BaseTable v-bind="cardData">
        <template #column_two="{ header }">
          {{ header }}
          <WikiTooltipComponent
            v-if="header"
            path="/bst/results/current-situation"
          />
        </template>

        <template #column_three="{ header }">
          {{ header }}
          <WikiTooltipComponent
            v-if="header"
            path="/bst/results/new-situation"
          />
        </template>

        <template #column_one-onbalanskosten="{ row }">
          {{ row.column_one }}
          <WikiTooltipComponent path="/bst/results/imbalance-costs" />
        </template>
      </BaseTable>
    </BaseCard>
  </BaseCard>
</template>

<script setup>
import {
  BaseCard,
  TextTitle,
  BaseTable,
  BaseButton,
} from "@repowerednl/ui-component-library"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"
import { useResultsStore } from "@/stores/useResultsStore"
import { toCurrency } from "@/helpers/currency-helper"
import { useDemoStore } from "@/stores/useDemoStore"

const demoStore = useDemoStore()
const resultsStore = useResultsStore()

/**
 * Creates a data structure for a given label, JSON name and optionally
 * available labels. The data structure consists of columns and rows, where each
 * row represents an item with corresponding values for each column.
 *
 * @param {string} labelName - The label name for the column.
 * @param {string} jsonName - The JSON name used to extract data for the rows.
 * @param {boolean} labelsAvailable - Optional. Determines whether labels for
 * 'column_two' and 'column_three' columns are available. Default is 'false'.
 *
 * @returns {object} - The created data frame object with columns and rows.
 */
function createDataStructure(labelName, jsonName, labelsAvailable = false) {
  return {
    columns: {
      column_one: { label: labelName },
      column_two: {
        label: labelsAvailable ? "Huidige situatie" : "",
        tooltip: "Dynamisch contract zonder batterij",
      },
      column_three: {
        label: labelsAvailable ? "Nieuwe situatie" : "",
        tooltip: "Dynamisch contract met batterij",
      },
    },
    rows: Object.values(resultsStore.results.financial_situation[jsonName]).map(
      (item) => {
        const hiddenValue = "-"
        return {
          column_one: item[labelName],
          column_two: demoStore.active
            ? hiddenValue
            : toCurrency(item["Huidige situatie"]),
          column_three: demoStore.active
            ? hiddenValue
            : toCurrency(item["Nieuwe situatie"]),
          // return labels as a lower- and kebab-case _id property
          _id: item[labelName].split(" ").join("-").toLowerCase(),
        }
      },
    ),
  }
}

/**
 * tableData represents an array of objects that contains table data.
 * Each object has a single property 'df' that represents a DataFrame.
 *
 * @typedef {Object[]} TableData
 */
const tableData = [
  createDataStructure("Kosten", "costs_table", true),
  createDataStructure("Opbrengsten", "yields_table"),
  createDataStructure("Resultaat", "result_table"),
]
</script>

<style lang="postcss">
.financial-table {
  table {
    thead {
      tr {
        @apply relative;

        th {
          @apply bg-transparent text-sm;
        }
      }
    }

    th:not(:first-child),
    td:not(:first-child) {
      width: 200px;
      @apply text-right;
    }

    tr {
      &:last-child {
        td {
          @apply font-bold;
        }
      }
    }
  }
}
</style>
