<template>
  <BaseButton
    color="secondary"
    small
    square
    :class="{ 'opacity-0': isHidden }"
    @click="takeSnapshot"
  >
    <BaseIcon name="download-2" filled />
  </BaseButton>
</template>

<script setup>
import { BaseIcon, BaseButton } from "@repowerednl/ui-component-library"
import { ref } from "vue"
import * as htmlToImage from "html-to-image"

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: false,
    default: "Undefined",
  },
})

const isHidden = ref(false)

const takeSnapshot = async () => {
  const node = document.getElementById(props.id)
  node.classList.add("!border-transparent")

  isHidden.value = true

  htmlToImage
    .toPng(node, { backgroundColor: "#fff" })
    .then(function (dataUrl) {
      let link = document.createElement("a")
      link.href = dataUrl
      link.download = `${props.name}.png`
      link.click()
    })
    .finally(() => {
      node.classList.remove("!border-transparent")
      isHidden.value = false
    })
}
</script>
