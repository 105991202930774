<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 1"
    title="Simulatie aanmaken"
    :hide-next-button="false"
    :hide-back-button="true"
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.simulationStepInvalid"
    :validation-state="wizardValidationStore.v$simulationRules"
    @confirm-btn="wizardValidationStore.v$simulationRules.$validate()"
  >
    <template #content>
      <div class="flex flex-col gap-2">
        <InputField
          v-model="wizardStore.simulation.name"
          data-cy="simulation-name"
          placeholder="Naam van simulatie"
          label="Naam nieuwe simulatie"
          :helper-text="
            !nameSubmittable
              ? `'${wizardStore.simulation.name}' is al in gebruik`
              : null
          "
          :state-error="!nameSubmittable"
          @update:model-value="
            wizardValidationStore.v$simulationRules.simulation.name.$touch()
          "
          @input="wizardStore.validateName(wizardStore.simulation.name)"
        />
      </div>
      <div class="flex flex-col gap-2">
        <p class="text-sm font-medium">
          Einde marktjaar
          <WikiTooltipComponent path="/bst/wizard/market-year" />
        </p>
        <DatePicker
          v-model="wizardStore.simulation.endDate"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$simulationRules.simulation.endDate,
              'Marktjaar',
            )
          "
          :state-error="
            !!wizardValidationStore.v$simulationRules.simulation.endDate.$errors
              .length
          "
        />
      </div>
      <RadioButtonList
        v-model="wizardStore.simulation.caseDescription"
        variant="default"
        data-cy="simulation-type"
        title="Waarvoor wil je de simulatie doen?"
        sub-title="Beschrijf hier je casus"
        :items="caseDescriptionOptions"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$simulationRules.simulation.caseDescription,
            'Type simulatie',
          )
        "
        :state-error="
          !!wizardValidationStore.v$simulationRules.simulation.caseDescription
            .$errors.length
        "
      />
    </template>
    <template #nextButton>Aanmaken</template>
    <template #summary>
      {{ wizardStore.simulation.name }} &bull;&nbsp;{{
        format(
          new Date(
            wizardStore.simulation.endDate.year,
            wizardStore.simulation.endDate.month,
          ),
          "yyyy-MMMM",
          { locale: nl },
        )
      }}
      &bull;&nbsp;{{ wizardStore.simulation.caseDescription }}
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { useWizardStore } from "@/stores/wizardStore"
import { InputField, RadioButtonList } from "@repowerednl/ui-component-library"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"
import WizardStep from "@/components/wizard/WizardStep.vue"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import DatePicker from "@/components/input/DatePicker.vue"
import format from "date-fns/format"
import { nl } from "date-fns/locale"

defineProps({
  /*
  Used to determine which step is currently active
  */
  isActive: {
    type: Boolean,
    required: true,
  },
  /*
  For determining wether to show the finished checkmark and summary of the inputfields.
  */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /*
  Used to determine if the step is enabled
  */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const wizardValidationStore = useWizardValidationStore()
const wizardStore = useWizardStore()
const wizardStepRef = ref()

const caseDescriptionOptions = [
  { label: "Opwek en verbruik", value: "opwek, verbruik" },
  { label: "Alleen opwek", value: "opwek" },
  { label: "Alleen verbruik", value: "verbruik" },
]

const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

const nameSubmittable = computed(
  () =>
    wizardValidationStore.v$simulationRules.simulation.validName.$errors
      .length === 0,
)

watch(
  () => wizardStore.simulation.endDate,
  (newValue) => {
    if (!newValue) {
      wizardStore.simulation.endDate = {
        month: 6,
        year: new Date().getFullYear(),
      }
    }
  },
)

defineExpose({ getValidationState })
</script>
