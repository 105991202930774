<template>
  <div
    class="simulation-item flex items-center justify-between rounded-2xl border border-gray-100 p-4"
    :class="
      !done
        ? 'bg-gray-50'
        : 'cursor-pointer transition-all duration-150 hover:bg-primary-50 hover:shadow'
    "
    :data-cy="`simulation-item-${itemNumber}`"
    @click="
      done &&
        !editName &&
        router.push({
          name: 'results',
          params: { uid: simulation.uid },
        })
    "
  >
    <!-- Simulation info -->
    <div>
      <!-- Name and status indicators -->
      <div class="flex items-center gap-2 rounded-lg">
        <h3 v-if="!editName" class="title text-lg font-medium text-gray-900">
          {{ simulation.name }}
        </h3>
        <div
          v-else
          v-click-outside="cancelEdit"
          class="flex items-center rounded-lg"
        >
          <div class="bg-transparent">
            <InputField
              ref="inputRef"
              v-model="wizardStore.newName"
              :state-error="!newNameSubmittable"
              :helper-text="returnHelperText()"
              @keyup.enter="handleSubmit"
              @keyup.esc="cancelEdit"
              @update:model-value="
                wizardValidationStore.v$editSimulationRules.newName.$touch()
              "
              @input="wizardStore.validateName(wizardStore.newName)"
              @click.stop
            >
              <BaseButton
                :disabled="!newNameSubmittable"
                @click.stop="handleSubmit"
              >
                Opslaan
              </BaseButton>
            </InputField>
          </div>
        </div>
        <BaseBadge v-if="pending" color="info">In de wachtrij</BaseBadge>
        <BaseBadge v-if="calculating">Bezig</BaseBadge>
        <BaseBadge v-if="failed" color="danger">Mislukt</BaseBadge>
      </div>
      <!-- Description -->
      <div class="text-sm text-gray">
        <template v-if="simulation.date_modified">
          Laatst gewijzigd:
          {{ format(new Date(simulation.date_modified), "d MMM y, HH:mm") }}
        </template>
        <template v-else>
          Aangemaakt op:
          {{ format(new Date(simulation.date_created), "d MMM y, HH:mm") }}
        </template>
        <template v-if="calculating">
          &bull; Beschikbaar vanaf ong.
          {{ format(new Date(simulation.date_estimated_completion), "HH:mm") }}
        </template>
      </div>
    </div>
    <!-- Menu button -->
    <div class="simulation-item-menu relative">
      <BaseButton color="subtle" square @click.stop="openMenu(index)">
        <BaseIcon name="more" filled class="text-lg" />
      </BaseButton>
      <ContextMenu ref="menuRef" :items="contextItems" class="right-0" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, nextTick } from "vue"
import { useRouter } from "vue-router"
import { format } from "date-fns"
import { useWizardStore } from "@/stores/wizardStore"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import vClickOutside from "@/composables/clickOutside"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import { validateFeedback } from "@/services/validationService"
import {
  BaseIcon,
  BaseButton,
  BaseBadge,
  InputField,
} from "@repowerednl/ui-component-library"
import ContextMenu from "@/components/ContextMenu.vue"

const props = defineProps({
  itemNumber: {
    type: Number,
    required: true,
  },
  simulation: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(["duplicate", "delete", "edit-name"])

const wizardStore = useWizardStore()
const wizardValidationStore = useWizardValidationStore()
const router = useRouter()
const menuRef = ref()
const editName = ref(false)
const inputRef = ref()

const done = computed(() => props.simulation.status === "done")
const pending = computed(() => props.simulation.status === "pending")
const calculating = computed(() => props.simulation.status === "calculating")
const failed = computed(() => props.simulation.status === "failed")

const newNameSubmittable = computed(
  () =>
    (!wizardValidationStore.v$editSimulationRules.newName.$errors.length &&
      wizardStore.simulation.validName) ||
    wizardStore.newName === props.simulation.name,
)

const contextItems = computed(() => {
  const items = []
  if (done.value) {
    items.push(
      {
        label: "Dupliceer",
        action: () => emit("duplicate", props.simulation.uid),
        icon: "file-copy",
      },
      {
        label: "Wijzig",
        action: () =>
          router.push({
            name: "wizard-edit",
            params: { uid: props.simulation.uid },
          }),
        icon: "pencil",
      },
      {
        label: "Wijzig naam",
        action: () => {
          wizardStore.newName = props.simulation.name
          nextTick(() => {
            inputRef.value.focus()
          })
          editName.value = true
        },
        icon: "input-field",
      },
    )
  }
  items.push({
    label: "Verwijder",
    action: () => emit("delete", props.simulation.uid),
    icon: "delete-bin",
    highlight: true,
  })
  return items
})

function openMenu() {
  menuRef.value.open()
}

function handleSubmit() {
  if (wizardStore.newName !== props.simulation.name) {
    emit("edit-name", props.simulation.uid, wizardStore.newName)
  }
  editName.value = false
}

function cancelEdit() {
  editName.value = false
}

function returnHelperText() {
  return validateFeedback(
    vuelidateErrors(
      wizardValidationStore.v$editSimulationRules.newName,
      "Naam",
    ),
    wizardStore.simulation.validName && newNameSubmittable.value,
    `Let op: ${wizardStore.newName} is al in gebruik`,
  )
}
</script>
