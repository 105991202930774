<template>
  <BaseCard class="bg-primary p-6">
    <TextTitle>Batterij</TextTitle>
    <div class="my-4 grid grid-cols-2 content-between gap-4">
      <BaseCard class="!bg-primary p-4 !text-white">
        <IconContainer>
          <BaseIcon class="text-primary" name="euro" />
        </IconContainer>

        <CardContent
          :title="
            toCurrency(resultsStore.results.battery_finances.total_market_value)
          "
          class="mt-2"
          content="Marktwaarde"
          title-suffix="per jaar"
        >
          <template #contentSuffix>
            <WikiTooltipComponent
              path="/bst/results/market-value"
              color="white"
            />
          </template>
        </CardContent>
      </BaseCard>
      <CardContent
        class="rounded-xl border border-gray-100 px-4 py-4"
        content="Cycli"
        :title="formatNum(resultsStore.results.battery_cycles.per_day, 1)"
        title-suffix="per dag"
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/battery-cycles" />
        </template>
      </CardContent>
    </div>

    <div
      class="mb-4 grid grid-cols-2 gap-4 rounded-xl border border-gray-100 py-4"
    >
      <CardContent
        class="px-4"
        content="Cycli"
        :title="formatNum(resultsStore.results.battery_cycles.per_year, 1)"
        title-suffix="per jaar"
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/battery-cycles" />
        </template>
      </CardContent>
      <CardContent
        class="px-4"
        content="Cycli totale levensduur"
        :title="resultsStore.results.battery_cycles.total_cycles"
      >
        <template #contentSuffix>
          <WikiTooltipComponent path="/bst/results/battery-lifespan" />
        </template>
      </CardContent>
    </div>
    <div id="marketRevenue" class="mb-4 rounded-xl border border-gray-100 p-4">
      <div class="flex items-center justify-between">
        <TextSubtitle>Marktopbrengst batterij per jaar</TextSubtitle>
        <SnapshotButton
          v-if="!demoStore.active"
          id="marketRevenue"
          name="Marktopbrengst"
        />
      </div>
      <div class="h-[313px]">
        <TimeseriesChart
          chart-type="bar"
          :series="chartData.series"
          :axes="chartData.axes"
          :data="revenueData"
          :x-limits="[revenueMinDate, revenueMaxDate]"
          time-unit="month"
        >
          <template #left-axis-title="{ title }">
            <div class="flex items-center">
              <BaseIcon
                class="mr-1 text-primary"
                name="money-euro-circle"
                filled
              />
              <p>{{ title }}</p>
            </div>
          </template>
        </TimeseriesChart>
      </div>
    </div>
    <div
      id="cumulativeMarketRevenue"
      class="mb-4 rounded-xl border border-gray-100 p-4"
    >
      <div class="flex items-center justify-between">
        <TextSubtitle
          >Cumulatieve marktopbrengst batterij per jaar</TextSubtitle
        >
        <SnapshotButton
          v-if="!demoStore.active"
          id="cumulativeMarketRevenue"
          name="Cumulatieve marktopbrengst"
        />
      </div>
      <div class="h-[313px]">
        <TimeseriesChart
          chart-type="area"
          :series="chartData.series"
          :axes="chartData.axes"
          :data="cumulativeData"
          :x-limits="[cumulativeMinDate, cumulativeMaxDate]"
          time-unit="month"
        >
          <template #left-axis-title="{ title }">
            <div class="flex items-center">
              <BaseIcon
                class="mr-1 text-primary"
                name="money-euro-circle"
                filled
              />
              <p>{{ title }}</p>
            </div>
          </template>
        </TimeseriesChart>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { useResultsStore } from "@/stores/useResultsStore"
import { useDemoStore } from "@/stores/useDemoStore"
import { getResolvedColors } from "@/services/themeService.js"
import { toCurrency } from "@/helpers/currency-helper"
import { formatNum } from "leaflet/src/core/Util"
import {
  BaseCard,
  BaseIcon,
  TextTitle,
  TextSubtitle,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"
import CardContent from "@/components/CardContent.vue"
import IconContainer from "@/components/general/IconContainer.vue"
import SnapshotButton from "@/components/general/SnapshotButton.vue"

const resultsStore = useResultsStore()
const demoStore = useDemoStore()
const colors = getResolvedColors()
const chartData = {
  axes: {
    finance: {
      title: "Opbrengst",
      format: "€ _",
    },
  },
  series: {
    Imbalance: {
      label: "Onbalans markt",
      axis: "finance",
      colorScale: colors.energy,
    },
  },
}

const revenueData = resultsStore.results.battery_finances.monthly_result_table
const cumulativeData =
  resultsStore.results.battery_finances.cumulative_result_table

const [revenueMinDate, revenueMaxDate] = getMinMaxDates(revenueData)
const [cumulativeMinDate, cumulativeMaxDate] = getMinMaxDates(cumulativeData)

function getMinMaxDates(data) {
  if (!data || Object.keys(data).length === 0) {
    return [null, null]
  }

  const dates = Object.keys(data)
  const dateObjects = dates.map((date) => new Date(date))
  const minDate = new Date(Math.min(...dateObjects))
  const maxDate = new Date(Math.max(...dateObjects))

  return [minDate, maxDate]
}
</script>
